import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/pals`;
const PalTasks = () => {
    const { groupId } = useParams();
    const [tasks, setTasks] = useState([]);
    const [groupName, setGroupName] = useState('');

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/${groupId}/tasks`);
                setTasks(response.data.tasks);
                setGroupName(response.data.groupName);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, [groupId]);

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Tasks for {groupName}
            </Typography>
            <List>
                {tasks.map((task) => (
                    <ListItem key={task._id}>
                        <ListItemText
                            primary={task.title}
                            secondary={`Due: ${new Date(task.dueDate).toLocaleDateString()} - Priority: ${task.priority}`}
                        />
                    </ListItem>
                ))}
            </List>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/pals/${groupId}/assign-task`}
                sx={{ marginTop: '20px' }}
            >
                Assign New Task
            </Button>
        </Container>
    );
};

export default PalTasks;
