import React, { useState } from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button, IconButton, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AddIcon from '@mui/icons-material/Add';

const GroupManagementPage = () => {
    const [members, setMembers] = useState(['John Doe', 'Jane Smith', 'David Brown']);
    const [inviteEmail, setInviteEmail] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openLeaveDialog, setOpenLeaveDialog] = useState(false);

    const handleInvite = () => {
        if (inviteEmail) {
            // Logic to send an invite goes here
            setInviteEmail('');
            alert(`Invite sent to ${inviteEmail}`);
        }
    };

    const handleRemoveMember = (member) => {
        setMembers(members.filter(m => m !== member));
    };

    const handleDeleteGroup = () => {
        // Logic to delete the group goes here
        alert('Group deleted');
        setOpenDeleteDialog(false);
    };

    const handleLeaveGroup = () => {
        // Logic to leave the group goes here
        alert('You left the group');
        setOpenLeaveDialog(false);
    };

    return (
        <Container maxWidth="md" sx={{ mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Manage Group
            </Typography>


            <Card>
                <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Invite Members
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            variant="outlined"
                            label="Email"
                            value={inviteEmail}
                            onChange={(e) => setInviteEmail(e.target.value)}
                            sx={{ flexGrow: 1, mr: 2 }}
                        />
                        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleInvite}>
                            Invite
                        </Button>
                    </Box>
                </CardContent>
            </Card>

            <Card sx={{ mt: 4, mb: 4 }}>
                <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Members
                    </Typography>
                    <List>
                        {members.map((member, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemText primary={member} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" color="secondary" onClick={() => handleRemoveMember(member)}>
                                            <PersonRemoveIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {index < members.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </CardContent>
            </Card>

            <Card sx={{ mb: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Group Name
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Group description goes here.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="primary" onClick={() => setOpenLeaveDialog(true)}>
                        Leave Group
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => setOpenDeleteDialog(true)}>
                        Delete Group
                    </Button>
                </CardActions>
            </Card>
            {/* Leave Group Dialog */}
            <Dialog open={openLeaveDialog} onClose={() => setOpenLeaveDialog(false)}>
                <DialogTitle>Leave Group</DialogTitle>
                <DialogContent>Are you sure you want to leave this group?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenLeaveDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleLeaveGroup} color="secondary">
                        Leave
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Group Dialog */}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Delete Group</DialogTitle>
                <DialogContent>Are you sure you want to delete this group? This action cannot be undone.</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteGroup} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default GroupManagementPage;
