import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, MenuItem, FormControlLabel, Checkbox, FormControl, InputLabel, Select, ListSubheader, Typography } from '@mui/material';
import { createTask } from '../../utils/taskApi';
import { savePresetTask, fetchPresets } from '../../utils/presetApi';
import { useNavigate } from 'react-router-dom';

const TaskForm = () => {
    const navigate = useNavigate();
    const [presetSave, setPresetSave] = useState(false);
    const [presetData, setPresetData] = useState([]);

    const [taskData, setTaskData] = useState({
        title: '',
        description: '',
        priority: 'Medium',
        dueDate: formatDateDisplay(new Date()),
        completed: false,
        recurring: false,
        recurrenceFrequency: 'daily'
    });
    useEffect(() => {

        const presetTasks = [
            {
                category: "Work-Related",
                tasks: [
                    { title: "Daily Stand-Up Meeting", description: "Quick meeting to discuss progress and blockers." },
                    { title: "Email Inbox Zero", description: "Organize and clear out your email inbox." },
                    { title: "Project Planning", description: "Outline and organize tasks for an upcoming project." },
                    { title: "Client Follow-Up", description: "Reach out to clients to follow up on ongoing projects." },
                    { title: "Report Preparation", description: "Compile and prepare reports for review." },
                    { title: "Team Sync-Up", description: "Quick meeting to align team members on daily goals and tasks." },
                    { title: "Code Review", description: "Review and provide feedback on a colleague's code." },
                    { title: "Market Research", description: "Gather data and insights on industry trends." },
                    { title: "Presentation Prep", description: "Prepare slides and content for an upcoming presentation." },
                    { title: "Client Proposal", description: "Draft a proposal for a potential client or project." },
                ],
            },
            {
                category: "Personal",
                tasks: [
                    { title: "Morning Exercise", description: "A short workout to start the day energized." },
                    { title: "Meal Prep", description: "Prepare meals for the upcoming week." },
                    { title: "Grocery Shopping", description: "Buy necessary groceries for the week." },
                    { title: "Read a Book", description: "Spend some time reading a book." },
                    { title: "Daily Journaling", description: "Write down your thoughts and reflections for the day." },
                    { title: "Meditation Session", description: "Spend time meditating to clear your mind and reduce stress." },
                    { title: "Plan Weekly Meals", description: "Organize meals for the week, ensuring a balanced diet." },
                    { title: "Social Media Detox", description: "Take a break from social media for the day." },
                    { title: "Family Outing", description: "Plan and enjoy a day out with family members." },
                    { title: "Budget Review", description: "Go through your personal finances and adjust your budget." },
                ],
            },
            {
                category: "Household Chores",
                tasks: [
                    { title: "Deep Clean the Bathroom", description: "Perform a thorough cleaning of the bathroom." },
                    { title: "Mow the Lawn", description: "Cut the grass and tidy up the yard." },
                    { title: "Wash the Windows", description: "Clean all windows to let in more light." },
                    { title: "Organize the Garage", description: "Tidy up the garage and declutter unnecessary items." },
                    { title: "Dust the Furniture", description: "Remove dust from all surfaces in the house." },
                ],
            },
            {
                category: "Health & Wellness",
                tasks: [
                    { title: "Yoga Practice", description: "Engage in a yoga session to improve flexibility and relaxation." },
                    { title: "Healthy Snack Prep", description: "Prepare healthy snacks to have throughout the day." },
                    { title: "Hydration Check", description: "Ensure you drink enough water throughout the day." },
                    { title: "Evening Walk", description: "Take a relaxing walk in the evening to wind down." },
                    { title: "Sleep Hygiene Routine", description: "Establish a routine that promotes better sleep quality." },
                ],
            },
            {
                category: "Learning & Development",
                tasks: [
                    { title: "Read Industry Articles", description: "Stay updated by reading the latest articles in your field." },
                    { title: "Attend a Webinar", description: "Join a webinar to learn something new or improve skills." },
                    { title: "Practice a New Language", description: "Spend time practicing or learning a new language." },
                    { title: "Complete an Online Course Module", description: "Finish a section of an ongoing online course." },
                    { title: "Skill Practice", description: "Dedicate time to practicing a specific skill (e.g., coding, writing)." },
                ],
            },
            {
                category: "Hobbies & Creative Tasks",
                tasks: [
                    { title: "Sketching", description: "Spend time drawing or sketching to express creativity." },
                    { title: "Garden Maintenance", description: "Tend to your garden, planting new flowers or vegetables." },
                    { title: "Cooking Experiment", description: "Try a new recipe or cooking technique." },
                    { title: "Music Practice", description: "Practice playing an instrument or learn a new song." },
                    { title: "Creative Writing", description: "Write a poem, short story, or start a novel." },
                ],
            },
            {
                category: "Financial",
                tasks: [
                    { title: "Review Investment Portfolio", description: "Check and update your investment strategy." },
                    { title: "Savings Goal Update", description: "Track progress towards your savings goals." },
                    { title: "Pay Off Debt", description: "Make a payment towards outstanding debt." },
                    { title: "Set Up Automatic Payments", description: "Automate bill payments to avoid late fees." },
                    { title: "Tax Preparation", description: "Organize documents and prepare for tax filing." },
                ],
            },
            {
                category: "Social & Relationship",
                tasks: [
                    { title: "Plan a Gathering", description: "Organize a get-together with friends or family." },
                    { title: "Write a Thank-You Note", description: "Express gratitude by writing a note to someone." },
                    { title: "Catch Up Call", description: "Call a friend or family member to reconnect." },
                    { title: "Host a Dinner Party", description: "Invite friends over for a dinner party." },
                    { title: "Date Night", description: "Plan a special evening out with your partner." },
                ],
            },
            {
                category: "Self-Care",
                tasks: [
                    { title: "Mindfulness Exercise", description: "Engage in a mindfulness activity to center yourself." },
                    { title: "Spa Day", description: "Treat yourself to a day of relaxation and pampering." },
                    { title: "Favorite Movie Marathon", description: "Watch your favorite movies for a relaxing evening." },
                    { title: "Journaling Session", description: "Write about your day, thoughts, and feelings." },
                    { title: "Digital Cleanup", description: "Organize files, clear out unused apps, and tidy up your digital space." },
                ],
            },
            {
                category: "Career Development",
                tasks: [
                    { title: "Update Resume", description: "Review and update your resume with recent achievements." },
                    { title: "LinkedIn Networking", description: "Connect with industry professionals on LinkedIn." },
                    { title: "Mock Interview Practice", description: "Prepare for upcoming interviews by practicing." },
                    { title: "Portfolio Enhancement", description: "Add new projects or updates to your professional portfolio." },
                    { title: "Set Career Goals", description: "Define and outline short-term and long-term career goals." },
                ],
            },
            {
                category: "Environmental Sustainability",
                tasks: [
                    { title: "Recycle Collection", description: "Sort and take out recyclables." },
                    { title: "Compost Food Waste", description: "Start or maintain a compost bin for food scraps." },
                    { title: "Energy Efficiency Check", description: "Inspect your home for energy-saving opportunities." },
                    { title: "Plant a Tree", description: "Contribute to the environment by planting a tree." },
                    { title: "Sustainable Shopping", description: "Research and purchase eco-friendly products." },
                ],
            },
            {
                category: "Mind & Body Wellness",
                tasks: [
                    { title: "Stretch Breaks", description: "Take short breaks throughout the day to stretch and move." },
                    { title: "Mindful Breathing Exercises", description: "Practice deep breathing to reduce stress." },
                    { title: "Healthy Meal Planning", description: "Plan nutritious meals for the week ahead." },
                    { title: "Bodyweight Exercises", description: "Perform a quick workout using just your body weight." },
                    { title: "Mental Health Check-In", description: "Reflect on your mental health and seek support if needed." },
                ],
            },
        ];
        const loadPresets = async () => {
            try {
                const presets = await fetchPresets();
                const pre = [{
                    category: "Custom",
                    tasks: presets
                }];
                setPresetData(pre.concat(presetTasks));
            } catch (error) {
                console.error('Failed to fetch presets:', error);
            }
        }
        loadPresets();
    }, []);
    const handleChange = (e) => {
        setTaskData({
            ...taskData,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    };
    const handlePresetChange = (e) => {
        setPresetSave(e.target.checked);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (presetSave) {
                await handleSavePreset();
            }
            taskData.dueDate = new Date(taskData.dueDate).toISOString();
            await createTask(taskData);
            navigate(-1);
        } catch (error) {
            console.error('Failed to create task:', error);
        }
    };
    const handleCancel = () => {
        navigate(-1); // This navigates back to the previous page
    };
    const handlePresetSelect = (selectedTask) => {
        if (selectedTask) {
            const task = JSON.parse(selectedTask); // Parse the selected task object
            setTaskData({
                ...taskData,
                title: task.title,
                description: task.description,
            });
        }
    };

    // Example of handling the save action in the task form
    const handleSavePreset = async (e) => {
        const presetTask = {
            title: taskData.title,
            description: taskData.description
        };
        savePresetTask(presetTask);
    }



    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: '20px auto',
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: 'white',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                boxSizing: 'border-box',
                '@media (max-width: 600px)': {
                    padding: '10px',
                },
            }}
        >
            <form
                onSubmit={handleSubmit}
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >

                <FormControl fullWidth margin="normal">
                    <InputLabel id="preset-task-label">Preset Tasks</InputLabel>
                    <Select
                        labelId="preset-task-label"
                        value={JSON.stringify({ title: taskData.title, description: taskData.description })}
                        onChange={(e) => handlePresetSelect(e.target.value)}
                        label="Preset Tasks"
                    >
                        <MenuItem value={JSON.stringify({ 'title': '', 'description': '' })}>
                            <em>Select a preset task...</em>
                        </MenuItem>
                        {presetData.flatMap((preset, index) => [
                            <ListSubheader key={`header-${index}`}>{preset.category}</ListSubheader>,
                            ...preset.tasks.map((task, taskIndex) => (
                                <MenuItem key={`${index}-${taskIndex}`} value={JSON.stringify(task)}>
                                    <Typography variant="body1">{task.title}</Typography>
                                    &nbsp;
                                    <Typography variant="body2" color="textSecondary">
                                        {task.description}
                                    </Typography>
                                </MenuItem>
                            )),
                        ])}
                    </Select>
                </FormControl>

                <TextField
                    label="Title"
                    name="title"
                    value={taskData.title}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Description"
                    name="description"
                    value={taskData.description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    select
                    label="Priority"
                    name="priority"
                    value={taskData.priority}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                >
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                </TextField>
                <TextField
                    label="Due Date"
                    name="dueDate"
                    type="datetime-local"
                    value={taskData.dueDate}
                    onChange={handleChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                />
                <FormControlLabel
                    control={<Checkbox name='recurring' checked={!!taskData.recurring} onChange={handleChange} />}
                    label="Recurring Task"
                />
                <FormControlLabel
                    control={<Checkbox name='presetSave' checked={!!presetSave} onChange={handlePresetChange} />}
                    label="Save Preset"
                />
                {taskData.recurring && (
                    <FormControl fullWidth>
                        <InputLabel id="recurrence-frequency-label">Recurrence Frequency</InputLabel>
                        <Select
                            name='recurrenceFrequency'
                            labelId="recurrence-frequency-label"
                            value={taskData.recurrenceFrequency}
                            onChange={handleChange}
                            label="Recurrence Frequency"
                        >
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                        </Select>
                    </FormControl>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button type="submit" variant="contained">
                        Save Task
                    </Button>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

function formatDateDisplay(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        h = '' + d.getHours(),
        m = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (h.length < 2)
        h = '0' + h;
    if (m.length < 2)
        m = '0' + m;
    return [year, month, day].join('-') + 'T' + [h, m].join(':');
}
export default TaskForm;
