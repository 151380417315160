import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, IconButton, } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AddTaskIcon from '@mui/icons-material/PlaylistAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
const PalsPage = () => {
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/pals`;
        const fetchGroups = async () => {
            try {
                const response = await axios.get(API_BASE_URL);
                setGroups(response.data.groups);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Pals
            </Typography>

            <Grid container spacing={4}>
                {groups.map((group) => (
                    <Grid item xs={12} sm={6} md={4} key={group.id}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {group.name}
                                </Typography>
                                <Typography>
                                    {group.description}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <IconButton
                                    component={RouterLink}
                                    to={`/pals/${group._id}/tasks`}
                                    color="primary"
                                    aria-label={`View tasks for ${group.name}`}
                                >
                                    <ListIcon />
                                </IconButton>
                                <IconButton
                                    component={RouterLink}
                                    to={`/pals/${group._id}/assign-task`}
                                    color="primary"
                                    aria-label={`Add tasks to ${group.name}`}
                                >
                                    <AddTaskIcon />
                                </IconButton>
                                <IconButton
                                    component={RouterLink}
                                    to={`/pals/${group._id}`}
                                    color="primary"
                                    aria-label={`Manage ${group.name}`}
                                >
                                    <ManageAccountsIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default PalsPage;