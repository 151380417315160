import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, MenuItem } from '@mui/material';
import axios from 'axios';

const AssignTaskToPal = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();
    const [taskData, setTaskData] = useState({ name: '', dueDate: '', priority: 'Medium' });
    const [error, setError] = useState('');

    const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/pals`;
    const handleChange = (e) => {
        setTaskData({ ...taskData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${API_BASE_URL}/${groupId}/tasks`, taskData);
            navigate(`/pals/${groupId}/tasks`);
        } catch (err) {
            setError('Failed to assign task. Please try again.');
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Assign Task to Pal
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Task Name"
                    name="name"
                    value={taskData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Due Date"
                    name="dueDate"
                    type="date"
                    value={taskData.dueDate}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Priority"
                    name="priority"
                    select
                    value={taskData.priority}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                >
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                </TextField>
                <Button variant="contained" color="primary" type="submit">
                    Assign Task
                </Button>
            </form>
        </Container>
    );
};

export default AssignTaskToPal;
