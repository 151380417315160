import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/user/Login';
import Register from './components/user/Register';
import PrivateRoute from './PrivateRoute';
import PublicDashboard from './components/PublicDashboard';
import TaskForm from './components/task/TaskForm';
import EditTaskForm from './components/task/EditTaskForm';
import Presetlist from './components/preset/PresetList';
import PresetForm from './components/preset/PresetForm';
import EditPresetForm from './components/preset/EditPresetForm';
import BaseLayout from './components/BaseLayout';
import Profile from './components/user/Profile';
import TaskList from './components/task/TaskList';
import GroupCreate from './components/group/GroupCreate';
import GroupManage from './components/group/GroupManage';
// import TaskAssign from './components/group/TaskAssign';
import PalsPage from './components/group/Pals';
import AssignTaskToPal from './components/group/AssignTaskToPal';
import PalTasks from './components/group/PalTasks';

import GroupManagementPage from './components/group/PalManage';

function App() {
  return (
    <Router>
      <BaseLayout>
        <Routes>
          {/* Route for unauthenticated users */}
          <Route path="/" element={<PublicDashboard />} />

          {/* Route for authenticated users */}
          {/* Tasks */}
          <Route path="/tasks" element={<PrivateRoute><TaskList /></PrivateRoute>} />
          <Route path="/create-task" element={<PrivateRoute><TaskForm /></PrivateRoute>} />
          <Route path="/edit-task/:id" element={<PrivateRoute><EditTaskForm /></PrivateRoute>} />

          {/* Presets */}
          <Route path="/presets" element={<PrivateRoute><Presetlist /></PrivateRoute>} />
          <Route path="/create-preset" element={<PrivateRoute><PresetForm /></PrivateRoute>} />
          <Route path="/edit-preset/:id" element={<PrivateRoute><EditPresetForm /></PrivateRoute>} />
          {/* Auth routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile/:id" element={<PrivateRoute><Profile /></PrivateRoute>} />
          {/* Group routes */}
          <Route path="/pals" element={<PrivateRoute><PalsPage /></PrivateRoute>} />
          <Route path="/pals/create" element={<PrivateRoute><GroupCreate /></PrivateRoute>} />
          <Route path="/pals/:groupId" element={<PrivateRoute><GroupManage /></PrivateRoute>} />
          {/* <Route path="/pals/:groupId/assign-task1" element={<PrivateRoute><TaskAssign /></PrivateRoute>} /> */}
          <Route path="/pals/:groupId/assign-task" element={<PrivateRoute><AssignTaskToPal /></PrivateRoute>} />
          <Route path="/pals/:groupId/tasks" element={<PrivateRoute><PalTasks /></PrivateRoute>} />
          <Route path="/pals/:groupId/" element={<PrivateRoute><GroupManagementPage /></PrivateRoute>} />
           
            

        </Routes>
      </BaseLayout>
    </Router>
  );
}

export default App;
