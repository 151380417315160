import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Button, Container } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/pals`;
const GroupManage = () => {
    const { groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const fetchGroupData = async () => {
            try {
                const groupResponse = await axios.get(`${API_BASE_URL}/${groupId}`);
                setGroup(groupResponse.data);
            } catch (error) {
                console.error('Error fetching group:', error);
            }
        };

        const fetchGroupTasks = async () => {
            try {
                const tasksResponse = await axios.get(`${API_BASE_URL}/${groupId}/tasks`);
                setTasks(tasksResponse.data.tasks);
            } catch (error) {
                console.error('Error fetching group tasks:', error);
            }
        };

        fetchGroupData();
        fetchGroupTasks();
    }, [groupId]);

    return (
        <Container>
            {group ? (
                <>
                    <h2>Manage Group: {group.name}</h2>
                    <h3>Tasks</h3>
                    <List>
                        {tasks.map((task) => (
                            <ListItem key={task._id}>
                                <ListItemText primary={task.taskId.title} />
                                <Button variant="contained" color="secondary">
                                    Edit
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                    <Button variant="contained" component={Link} to={`/groups/${groupId}/add-task`}>
                        Add Task
                    </Button>
                    <Button variant="contained" component={Link} to={`/groups/${groupId}/invite`}>
                        Invite Members
                    </Button>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </Container>
    );
};

export default GroupManage;
