import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/pals`;
const GroupCreate = () => {
    const [groupName, setGroupName] = useState('');
    const navigate = useNavigate();

    const handleCreateGroup = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/create`, { name: groupName });
            alert('Group created successfully');
            navigate(`/pals/${response.data.group._id}`);
        } catch (error) {
            console.error('Error creating group:', error);
            alert('Failed to create group');
        }
    };

    return (
        <Container>
            <h2>Create a New Group</h2>
            <TextField
                label="Group Name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleCreateGroup}>
                Create Group
            </Button>
        </Container>
    );
};

export default GroupCreate;
