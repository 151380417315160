import React, { useEffect, useState, useCallback, useContext } from 'react';
import { List, ListItem, ListItemText, IconButton, Box, Chip, Checkbox, LinearProgress, Button, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { fetchTasks, deleteTask, updateTask, updateTaskOrder, completeTask } from '../../utils/taskApi';
import Timer from '../Timer';

import { AuthContext } from '../../context/Auth';
const TaskList = () => {
    const [tasks, setTasks] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);
    const date = new Date(selectedDate);
    date.setHours(0, 0, 0, 0); // Normalize time to avoid time zone issues
    const loadTasks = useCallback(async () => {
        try {
            if (user) {
                const tasks = await fetchTasks();
                setTasks(tasks.sort((a, b) => a.order - b.order));
            }
        } catch (error) {
            console.error('Failed to fetch tasks:', error);
        }
    }, [user]);
    useEffect(() => {
        loadTasks();
    }, [loadTasks]);

    const handlePreviousDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        setSelectedDate(newDate);
    };

    const handleNextDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        setSelectedDate(newDate);
    };
    const handleDateChange = (event) => {
        var date1 = event.target.value + "T00:00:00";
        setSelectedDate(date1);
    };

    const moveTask = (index, direction) => {
        const newTasks = [...filteredTasks];
        const targetIndex = index + direction;

        if (targetIndex < 0 || targetIndex >= newTasks.length) {
            return; // Index out of bounds, do nothing
        }

        const task = newTasks[index];
        newTasks.splice(index, 1);
        newTasks.splice(targetIndex, 0, task);
        setTasks(newTasks);

        updateTaskOrder(newTasks.map(t => t._id)).catch(error => console.error('Failed to update task order:', error));
    };

    const handleDelete = async (id) => {
        try {
            await deleteTask(id);
            loadTasks();
        } catch (error) {
            console.error('Failed to delete task:', error);
        }
    };

    const handleToggleCompleted = async (task) => {
        try {
            const updatedTask = { ...task, completed: !task.completed };
            if (updatedTask.completed) {

                await completeTask(updatedTask._id)
            } else {
                await updateTask(updatedTask._id, updatedTask);

            }
            loadTasks();
        } catch (error) {
            console.error('Failed to update task:', error);
        }
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'High':
                return 'red';
            case 'Medium':
                return 'orange';
            case 'Low':
                return 'green';
            default:
                return 'gray';
        }
    };

    const calculateProgress = () => {
        if (tasks.length === 0) return 0;
        const completedTasks = filteredTasks.filter((task) => task.completed).length;
        return (completedTasks / filteredTasks.length) * 100;
    };


    const filteredTasks = tasks.filter(task => {
        var uncomp = !task.completed && formatDateDisplay(task.dueDate) < formatDateDisplay(selectedDate)
            && formatDateDisplay(selectedDate) === formatDateDisplay(new Date());
        return !selectedDate || uncomp || (formatDateDisplay(task.dueDate) === formatDateDisplay(selectedDate));
    });


    function formatDateDisplay(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    return (
        <Box sx={{ width: '100%', maxWidth: '600px', margin: '20px auto', textAlign: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
                <Button
                    value={selectedDate} onClick={handlePreviousDay} sx={{ minWidth: '40px' }}>
                    <ArrowBack />
                </Button>
                {<TextField
                    type="date"
                    value={formatDateDisplay(selectedDate)}
                    onChange={handleDateChange}
                    sx={{ marginBottom: '20px' }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                />}

                <Button onClick={handleNextDay} sx={{ minWidth: '40px' }}>
                    <ArrowForward />
                </Button>
            </Box>
            <Timer workDuration={25} breakDuration={5} />
            <Button component={Link} to="/create-task" variant="contained" sx={{ marginBottom: '20px' }}>
                Create New Task
            </Button>
            <LinearProgress variant="determinate" value={calculateProgress()} sx={{ marginBottom: '20px' }} />

            <List>
                {filteredTasks.map((task, index) => (
                    <ListItem key={task._id} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            checked={task.completed}
                            onChange={() => handleToggleCompleted(task)}
                        />
                        <ListItemText
                            primary={<>{task.title} <Chip label={task.priority} style={{ backgroundColor: getPriorityColor(task.priority), color: 'white' }} /></>}
                            secondary={
                                <>
                                    {task.description && <span>{task.description}<br /></span>}
                                    Due: {
                                        formatDateDisplay(task.dueDate) < formatDateDisplay(selectedDate) ?
                                            <span style={{ color: 'red' }}>{new Date((task.dueDate)).toLocaleDateString()} </span> :
                                            new Date((task.dueDate)).toLocaleTimeString()}<br />

                                </>
                            }
                            style={{ textDecoration: task.completed ? 'line-through' : 'none' }}
                        />
                        <IconButton onClick={() => moveTask(index, -1)} disabled={index === 0}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton onClick={() => moveTask(index, 1)} disabled={index === tasks.length - 1}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="edit" onClick={() => navigate(`/edit-task/${task._id}`)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(task._id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default TaskList;
